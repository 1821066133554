import React, { useCallback, useEffect, useState } from 'react';
import styled from 'styled-components';
import { ArrowLeft, Check, Facebook, Link, Twitter } from 'react-feather';

import { ThemedText } from '@theme/text';
import { colors } from '@theme/colors';
import { Z_INDEX } from '@theme/zIndex';
import { RowBetween } from '@components/layouts/Row';
import { Overlay } from '@components/modals/Overlay';
import { ListingRowData } from '@components/Listings/Seller/ListingsTable';
import { Event } from '@helpers/types';
import { formatEventDateTime } from '@helpers/dateFormat';


interface ShareListingModalProps {
  onBackClick: () => void;
  listingDetails: ListingRowData;
  listingEvent: Event;
};

export const ShareListingModal: React.FC<ShareListingModalProps> = ({
  onBackClick,
  listingDetails,
  listingEvent
}) => {

  const shareUrl = `https://www.tickets.zkp2p.xyz/${listingEvent.id}?listingId=${listingDetails.listingId}`;

  const twitterText =`I'm selling tickets for ${listingEvent.name} on ${formatEventDateTime(listingEvent.startTime)} (via @zkp2p)!`;

  /*
   * State
   */

  const [copied, setCopied] = useState<boolean>(false);

  /*
   * Handlers
   */

  const copyToClipboard = (text: string) => {
    navigator.clipboard.writeText(text).then(() => {
      setCopied(true);

      setTimeout(() => setCopied(false), 2000);
    });
  };

  const handleCopyClick = (textToCopy: string) => {
    if (!copied) {
      copyToClipboard(textToCopy);
    }
  };

  const handleOverlayClick = () => {
    onBackClick();
  };

  /*
   * Component
   */

  return (
    <ModalAndOverlayContainer>
      <Overlay onClick={handleOverlayClick}/>

      <ModalContainer>
        <RowBetween>
          <div style={{ flex: 0.25 }}>
            <button
              onClick={handleOverlayClick}
              style={{ background: 'none', border: 'none', cursor: 'pointer' }}
            >
              <StyledArrowLeft/>
            </button>
          </div>

          <ThemedText.HeadlineSmall style={{ flex: '1', margin: 'auto', textAlign: 'center' }}>
            Share Listing
          </ThemedText.HeadlineSmall>

          <div style={{ flex: 0.25 }}/>
        </RowBetween>

        <BodyContainer>
          <EventDetailsContainer>
            <DetailsTitle>
              Event To Share
            </DetailsTitle>

            <HorizontalDivider/>

            <DetailsContainer>
              <DetailsValue>{listingEvent.name}</DetailsValue>
              <DetailsValue>{formatEventDateTime(listingEvent.startTime)}</DetailsValue>
              <DetailsValue>{listingDetails.ticketsSummaryText}</DetailsValue>
            </DetailsContainer>
          </EventDetailsContainer>

          <SharableLinksContainer>
            <ShareButton onClick={() => window.open(`https://twitter.com/share?text=${twitterText}&url=${encodeURIComponent(shareUrl)}`, '_blank')}>
              <StyledTwitter/>
              <ShareText>Share on Twitter</ShareText>
            </ShareButton>
            <ShareButton onClick={() => window.open(`https://warpcast.com/~/compose?text=${twitterText}&embeds[]=${encodeURIComponent(shareUrl)}`, '_blank')}>
              <StyledWarpcastIcon width="24" height="24" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
                <path d="M4.11841 1H19.5982V4.09052H24L23.0775 7.18179H22.2964V19.6873C22.6881 19.6873 23.0061 20.0012 23.0061 20.3892V21.2321H23.1481C23.5406 21.2321 23.8587 21.5468 23.8587 21.9348V22.7778H15.9053V21.9348C15.9053 21.5468 16.2233 21.2321 16.6157 21.2321H16.7578V20.3892C16.7578 20.0519 16.9984 19.7702 17.3187 19.7024L17.3037 12.8021C17.0526 10.0451 14.7107 7.88443 11.8583 7.88443C9.00593 7.88443 6.66403 10.0451 6.41293 12.8021L6.3979 19.6963C6.7768 19.7521 7.24293 20.0412 7.24293 20.3892V21.2321H7.38502C7.77671 21.2321 8.09473 21.5468 8.09473 21.9348V22.7778H0.142092V21.9348C0.142092 21.5468 0.460107 21.2321 0.8518 21.2321H0.993892V20.3892C0.993892 20.0012 1.31191 19.6873 1.70436 19.6873V7.18179H0.923221L0 4.09052H4.11841V1Z"></path>
              </StyledWarpcastIcon>
              <ShareText>Share on Warpcast</ShareText>
            </ShareButton>
            <ShareButton onClick={() => window.open(`https://www.facebook.com/sharer/sharer.php?u=${encodeURIComponent(shareUrl)}`, '_blank')}>
              <StyledFacebook />
              <ShareText>Share on Facebook</ShareText>
            </ShareButton>
            <ShareButton onClick={() => handleCopyClick(shareUrl)}>
              {copied ? <StyledCheck/> : <StyledLink/>}
              <ShareText>Copy</ShareText>
            </ShareButton>
          </SharableLinksContainer>
          
        </BodyContainer>
      </ModalContainer>
    </ModalAndOverlayContainer>
  );
};

const ModalAndOverlayContainer = styled.div`
  width: 100vw;
  height: 100vh;
  display: flex;
  justify-content: center;
  position: fixed;
  align-items: flex-start;
  top: 0;
  left: 0;
  z-index: ${Z_INDEX.overlay};
`;

const StyledArrowLeft = styled(ArrowLeft)`
  color: ${colors.black};
`;

const ModalContainer = styled.div`
  max-height: 80vh;
  width: 80vw;
  max-width: 400px;
  display: flex;
  flex-direction: column;
  border-radius: 16px;
  border: 1px solid rgba(255, 255, 255, 0.2);
  padding: 1.5rem 1.5rem;
  background-color: ${colors.container};
  color: ${colors.black};
  z-index: ${Z_INDEX.buy_modal};
  gap: 1rem;
  overflow-y: auto;

  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
`;

const BodyContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  gap: 0.5rem;
`;

const EventDetailsContainer = styled.div`
  display: flex;
  flex-direction: column;
  padding: 1rem 0rem;
  align-items: flex-start;
  justify-content: flex-start;
  border-radius: 16px;
  border: 1px solid ${colors.defaultBorderColor};
  background-color: #FAF9F6;
`;

const HorizontalDivider = styled.div`
  width: 100%;
  border-top: 1px solid ${colors.defaultBorderColor};
  margin: 0.75rem 0 1rem;
`;

const DetailsTitle = styled.div`
  font-size: 14px;
  padding: 0 1rem;
  color: ${colors.darkText};
  background-color: ${colors.container};
`;

const DetailsContainer = styled.div`
  display: flex;
  flex-direction: column;
  padding: 0 1rem;
  gap: 0.5rem;
`;

const DetailsValue = styled.div`
  font-size: 14px;
  color: #505458;
`;

const SharableLinksContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 1rem;
  margin-top: 1rem;
`;

const ShareText = styled.span`
  font-size: 14px;
  color: ${colors.container};

  &:hover {
    color: ${colors.cardTitleText};
  }
`;

const StyledTwitter = styled(Twitter)`
  height: 24px;
  width: 24px;
  color: ${colors.container};
`;

const StyledFacebook = styled(Facebook)`
  height: 24px;
  width: 24px;
  color: ${colors.container};
`;

const StyledCheck = styled(Check)`
  height: 24px;
  width: 24px;
  color: ${colors.container};
`;

const StyledLink = styled(Link)`
  height: 24px;
  width: 24px;
  color: ${colors.container};
`;

const StyledWarpcastIcon = styled.svg`
  height: 24px;
  width: 24px;
  color: ${colors.container};

  path {
    fill: ${colors.container};
  }
`;

const ShareButton = styled.button`
  display: flex;
  align-items: center;
  gap: 0.5rem;
  padding: 0.5rem 1rem;
  border: 1px solid ${colors.cardTitleText};
  border-radius: 8px;
  background-color: ${colors.cardTitleText};
  // box-shadow: inset -3px -6px 4px rgba(0, 0, 0, 0.16);
  cursor: pointer;
  transition: background-color 0.2s;

  &:hover {
    background-color: ${colors.container};

    ${ShareText}, ${StyledTwitter}, ${StyledFacebook}, ${StyledCheck}, ${StyledLink} {
      color: ${colors.cardTitleText};
    }

    ${StyledWarpcastIcon} path {
      fill: ${colors.cardTitleText};
    }
  }
`;
